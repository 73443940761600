import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import './Bord.css';



import logime from './pngs/lot.png'

/*import dice from './pngs/dice.gif';
import giphypng from './pngs/giphy.png';
import giphygif from './pngs/giphy.gif';*/

/*import {jwtDecode} from 'jwt-decode';*/

//For auth
//menu  namename show
//then lo buy ticket landpage

function Bord() {
    const [auth, setAuth] = useState(false);
    const [message, setMessage] = useState('')
    const [name, setName] = useState('')
    const [id, setId] = useState('')
    axios.defaults.withCredentials = true;

    const [showGif, setShowGif] = useState(false);
    const [diceResult, setDiceResult] = useState(null);
    const [buttonStates, setButtonStates] = useState(Array(6).fill(false));
    const [inputBet, setInputBet] = useState(0);
    const [balance, setBalance] = useState(0);
    const [nameID, setNameID] = useState("");
        
    const [userNames, setUserNames] = useState([]);
    const [userNamesGame, setUserNamesGame] = useState([]);

    const [chosenUser, setChosenUser] = useState(null);
    const [countdown, setCountdown] = useState(null);

    const [winners, setWinners] = useState([]);

    const [isHighlighting, setIsHighlighting] = useState(false);

    const [tempShowHighlight, setTempShowHighlight] = useState(false);
    const [effectTookPlace, setEffectTookPlace] = useState(true);

    const [repDiv, setRepDiv] = useState(false);

    const navigate = useNavigate()

    useEffect(() => {
      const fetchUserData = async () => {
          try {
              /*const token = localStorage.getItem('token');*/
              const token = document.cookie.split('token=')[1];
              const response = await axios.get(`${process.env.REACT_APP_A}/user`, {
                  headers: { Authorization: `Bearer ${token}` }
              });
  
              if (response.data.success) {
                  setAuth(true);
                  setName(response.data.user.name);
                  setBalance(response.data.user.balance);
                  setId(response.data.user.id?.$oid || response.data.user.id);
                  setNameID(response.data.user.id);
              } else {
                  setAuth(false);
                  setMessage(response.data.message);
              }
          } catch (err) {
              console.log(err);
          }
      };
  
      fetchUserData();
  }, []);

          // Fetch all data in a single API call
    useEffect(() => {
      const fetchAllData = async () => {
          try {
              const { data } = await axios.get(`${process.env.REACT_APP_A}/getAllData`, { withCredentials: true });
              setUserNames(data.users);
              setUserNamesGame(data.gameUsers);
              setWinners(data.winners);
          } catch (err) {
              console.log(err);
          }
      };
      fetchAllData();
  }, []);

  const highlightUsers = () => {
      if (isHighlighting) return;
      setIsHighlighting(true);

      console.log('Highlighting Users');
      console.log('Winners:', winners);
      console.log('Chosen User:', chosenUser);
      const userBoxes = document.querySelectorAll('.UserNameBox');
      let currentIndex = 0;

      setTempShowHighlight(true);
      setRepDiv(true);
      
      const highlightInterval = setInterval(() => {
          // Apply yellow border to the current user
          userBoxes[currentIndex].style.border = '6px solid yellow';
  
          // Remove yellow border from the previous user
          if (currentIndex > 0) {
              userBoxes[currentIndex - 1].style.border = '';
          } else if (currentIndex === 0) {
              userBoxes[userBoxes.length - 1].style.border = '';
          }
  
          currentIndex++;
  
          // Reset index if it goes beyond the number of users
          if (currentIndex >= userBoxes.length) {
              currentIndex = 0;
          }
      }, 150);

      // Additional effect
      const intervalId = setInterval(() => {
          setRepDiv(prevRepDiv => !prevRepDiv);
      }, 1500);

      // Stop highlighting after 10 seconds (same duration as the lottery countdown)
      setTimeout(() => {
          clearInterval(highlightInterval);
          clearInterval(intervalId);

          // Clear yellow borders from all users except the chosen user
          userBoxes.forEach((userBox, index) => {
              if (userBox.textContent.trim() !== chosenUser) {
                  userBox.style.border = '';
              } else {
                  if (!tempShowHighlight) {
                      userBox.style.border = '6px solid yellow';
                  } else {
                      userBox.style.border = '';
                  }
              }
          });

          setTempShowHighlight(false);
          console.log('Took', effectTookPlace);
          setEffectTookPlace(false);
          setRepDiv(false);
          setIsHighlighting(false);
      }, 10000);
  };

  useEffect(() => {
      console.log("useEffect triggered with winners:", winners);
      if (winners.length > 0) {
          // Call highlightUsers after a short delay to allow DOM rendering
          const delay = setTimeout(() => {
              highlightUsers();
          }, 100);

          // Cleanup function to clear the timeout in case the component unmounts
          return () => {
              clearTimeout(delay);
          };
      }
  }, [winners]);
        

        

        /*useEffect(() => {
            console.log('Updated Balance:', balance);
            console.log('ID', nameID);
            if (auth && id) {
                getUpdatedBalance(id);
            }
        }, [auth, id]);*/

            /*useEffect(() => {
                console.log('ID', nameID);
                if (auth) {
                    console.log('ID', nameID);
                    getUpdatedBalance(id)
                    console.log("This worked reached")
                        .then(updatedBalance => {
                            setBalance(balance);
                            console.log('Updated Balance:', balance);
                            console.log("Balance updated");
                        })
                        .catch(error => {
                            console.error('Error updating balance:', error);
                        });
                }
            }, [auth, id, nameID]);*/

         
      
          /*const handleDelete = () => {
            console.log('Before logout request');
              
            axios.get('https://dice-six-plum.vercel.app/logout', { withCredentials: true })
            .then(res => {
                console.log('request');
                console.log('Logout Response:', res.data);
                document.cookie = 'token=${res.data.Token}; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; Secure; SameSite=None;';
    
                /*setAuth((prevAuth) => {
                console.log('Auth set to false. Previous value:', prevAuth);
                return false; // Set to false
                    
                });*/
                /*setAuth(false);*/

                /*await new Promise(resolve => setTimeout(resolve, 100));

                // Update the authentication state directly to false
                setAuth(false);

                

                console.log('Auth set to false. Current value:', auth);
                window.location.reload(true);
            }).catch(err => console.log(err));

            console.log('After logout request');
          }*/


            /*this for some reason actually deletes 
            all cookies, client and server, in deployment */
            const handleDelete = () => {
              console.log('Before logout request');

              // Clear authentication state
              setAuth(false);
              setWinners([]);
              console.log('About auth:', auth);
              
              /*const token = localStorage.getItem('token');*/ // Retrieve the token from localStorage
              const token = document.cookie.split('token=')[1];

              if (token) {
                  axios.get(`${process.env.REACT_APP_A}/logout`, {
                      headers: { Authorization: `Bearer ${token}` }, // Send the token in the Authorization header
                  })
                  .then(response => {
                      console.log('Logout Response:', response.data);
              
                      alert(`Logout successful\nToken: ${response.data.Token}`);
              
                      // Clear the token from localStorage
                      /*localStorage.removeItem('token');*/
                      document.cookie = 'token=; Max-Age=0; path=/; Secure; SameSite=None;';
              
                      // Update the authentication state directly to false
                      setAuth(false);
              
                      console.log('Auth set to false. Current value:', auth);
              
                      // Reload the window or handle any other necessary actions
                      /*window.location.reload(true);*/
                      navigate('/login');
                  })
                  .catch(error => {
                      console.error('Logout Error:', error);
                      // You can log more details about the error if needed
                  })
                  .finally(() => {
                      console.log('After logout request');
                  });
              } else {
                  console.error('No token found');
                  navigate('/login'); // Redirect to login if no token is found
              }
              
            };



          

            

              
            return (
              <div className='Bord'>
                  <div className='Bord'>
                      <div className='LoginMenu'>
                          <div className='iframediv'>
                              <iframe
                                  title='Countdown'
                                  width='100%'
                                  height='300'
                                  frameBorder='0'
                                  scrolling='no'
                                  src='https://www.tickcounter.com/countdown/5648842/lote-cuentabaja'
                              ></iframe>
                          </div>
                          <div className='pool'>
                              <h3>BOTE:</h3>
                              <div className='prize'><h1>&nbsp;$ 1 . 7 5 </h1></div>
                          </div>
                          <div className='bordMenu'>
                              <div className='webtitle'>
                                  <div className='logodiv'><img src={logime} className='logolott' alt="Logo" /></div>
                                  <div className='title'>LOTESIMPLE</div>
                              </div>
                              <div className='menudivBord'>BIENVENIDO {name}</div>
                              <div>
                                  <button className='menudivLogBord' onClick={handleDelete}>Logout</button>
                              </div>
                              <div>
                                  <button className='menudivBuyBord'>
                                      <Link to='/ticket' className='menudivBuyInkBord'>COMPRAR TICKET</Link>
                                  </button>
                              </div>
                          </div>
                      </div>
                      <div className='users'>
                          <div className='UserList'>
                              <div className='winnertab'>
                                  <div className='otherdiv'>
                                      <h3>JUGADORES&#9660;</h3>
                                  </div>
                                  <div className='winnerdiv'>
                                      <div>
                                          {tempShowHighlight && !effectTookPlace && (
                                              <h3>GANADOR:</h3>
                                          )}
                                          {tempShowHighlight && effectTookPlace && (
                                              <h3>GANADOR:</h3>
                                          )}
                                          {!tempShowHighlight && !effectTookPlace && (
                                              <h3>GANADOR: <div className='winame'>{chosenUser ? chosenUser.name : winners.map(winner => winner.name).join(', ')}</div></h3>
                                          )}
                                      </div>
                                  </div>
                              </div>
                              <div className='UserNameContainer'>
                                  {userNames.map((user, index) => {
                                      return (
                                          <div
                                              key={index}
                                              className={`UserNameBox ${!tempShowHighlight && winners.some(winner => winner.name === user.name) ? 'Winner' : ''}`}
                                          >
                                              {user.name}
                                          </div>
                                      );
                                  })}
          
                                  {userNamesGame.map((user, index) => {
                                      return (
                                          <div
                                              key={index}
                                              className={`UserNameBox ${!tempShowHighlight && winners.some(winner => winner.name === user.name) ? 'Winner' : ''}`}
                                              style={{ backgroundColor: '#d88d47', color: 'white' }}
                                          >
                                              {user.name}
                                          </div>
                                      );
                                  })}
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          );
          
        
    
};

export default Bord;
