import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import './ticket.css';

function Ticket() {
    const [auth, setAuth] = useState(false);
    const [message, setMessage] = useState('')
    const [name, setName] = useState('')
    const [namename, setNamename] = useState('')
    const [id, setId] = useState('')
    axios.defaults.withCredentials = true;

    const [email, setEmail] = useState('');

    const [success, setSuccess] = useState(false);

    const [userNameList, setUserNameList] = useState([]);
    const [userNameListTwo, setUserNameListTwo] = useState([]);
    const [isUsernameAvailable, setIsUsernameAvailable] = useState(false);
    const [hasStartedTyping, setHasStartedTyping] = useState(false);
    const [formValid, setFormValid] = useState(false);

    const [nameEmpty, setNameEmpty] = useState(true);

    /*const [selectedLottocoins, setSelectedLottocoins] = useState('');*/

    const location = useLocation();
    /*const selectedLottocoins = location.state.selectedLottocoins || '';*/

    /*const [values, setValues] = useState({
        email: '',
        password: ''
    })*/

    const [values, setValues] = useState({
        email: '',
        password: ''
    })

    const navigate = useNavigate()
    axios.defaults.withCredentials = true;

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                /*const token = localStorage.getItem('token');*/
                const token = document.cookie.split('token=')[1];
                const response = await axios.get(`${process.env.REACT_APP_A}/user`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
    
                if (response.data.success) {
                    setAuth(true);
                    setNamename(response.data.user.name);
                    console.log('namename set:', response.data.user.name);
                    console.log('name:', namename)
                    /*setBalance(response.data.user.balance);
                    setId(response.data.user.id?.$oid || response.data.user.id);
                    setNameID(response.data.user.id);*/
                } else {
                    setAuth(false);
                    setMessage(response.data.message);
                }
            } catch (err) {
                console.log(err);
            }
        };
    
        fetchUserData();
    }, []);
    
    useEffect(() => {
        /*console.log('NAME:', name);*/
        console.log('NAMENAME:', namename);
    }, [/*name, */namename]);

    useEffect(() => {
        // Fetch necessary data from backend on component mount
        fetchData();
        /*console.log('NAMEE:', name)
        console.log('namee:', namename)*/
    }, []);

    const fetchData = async () => {
        try {
            // Example fetch from UsersGameModel and paidModel
            const nameDataList = await axios.get(`${process.env.REACT_APP_A}/getUsersNameList`);
            const nameDataListTwo = await axios.get(`${process.env.REACT_APP_A}/getUsersNameListTwo`);

            // Assuming the responses contain necessary data
            setUserNameList(nameDataList.data.usernames);
            setUserNameListTwo(nameDataListTwo.data.usernames);
            console.log(nameDataList.data.usernames)
            console.log(nameDataListTwo.data.usernames)
        
            /*console.log('name:', namename)*/
        } catch (error) {
            console.error('Error fetching data:', error);
            // Handle error if needed
        }
    };
    /*const handleSubmit = (event) => {
        event.preventDefault();
        
        .then(res => {
            if(res.data.Status === "Success") {
                navigate('/Bord')
            } else {
                alert(res.data.Error);
            }
        })
        .catch(err => console.log(err));
    } */

    /*useEffect(()=> {
        axios.get('http://localhost:3001')
          .then(res => {
              if(res.data.Status === "Success") {
                  console.log(res);
                  setAuth(true)
                  setName(res.data.name)
                  
                  
                  setId(res.data.id?.$oid || res.data.id);
                  
              } else {
                  setAuth(false)
                  setMessage(res.data.Error)
              }
          })
          .catch(err => console.log(err));
      }, [])*/

      /*useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const lottocoins = searchParams.get('lottocoins');
        setSelectedLottocoins(lottocoins);
    }, [location.search]);*/

    const generateRandomPassword = () => {
        const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()';
        let password = '';
        for (let i = 0; i < 8; i++) {
            password += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return password;
    };

    const generateRandomEmail = () => {
        const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let email = '';
        for (let i = 0; i < 5; i++) {
            email += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return email + '@short.com';
    };

    /*const handleSubmit = async (event) => {
        event.preventDefault();

        const randomPassword = generateRandomPassword();
        const randomEmail = generateRandomEmail();

        const registrationData = {
            name,
            email: randomEmail,
            password: randomPassword,
             // Assuming name is already being set correctly
            btc: email // Assuming BTC address is being entered in the email field
        };

        const gameData = {
            name,
            btc: email // Assuming BTC address is being entered in the email field
        };
        
        try {
            /*const res = await axios.post('http://localhost:3001/sendPurchaseInfo', {
                name,
                email,
                
            });

            if (res.data.success) {
                
                setSuccess(true)
                navigate('/successbuy', { state: { success: res.data.success } });
            } else {
                setSuccess(false)
                navigate('/successbuy', { state: { success: res.data.success } });
            }

            const registerRes = await axios.post('http://localhost:3001/register', registrationData, { withCredentials: true });

            if (registerRes.status === 200) {
                console.log(registerRes);
                navigate('/login');
            } else {
                console.error('Registration failed:', registerRes);
            }

        } catch (error) {
            console.error('Error sending purchase information:', error);
            alert('Failed to send purchase information.');
            setSuccess(false);
            navigate('/successbuy', { state: { success: false } });
        }
    }*/

    const handleUsernameChange = (event) => {
        const { value } = event.target;
        /*setValues({ ...values, name: value });*/
        setName(value);

        if (!hasStartedTyping && value) {
            setHasStartedTyping(true);
        }

        // Check if the username is available in either list
        if (userNameList.includes(value) || userNameListTwo.includes(value)) {
            setIsUsernameAvailable(false);
        } else {
            setIsUsernameAvailable(true);
        }
       
        setNameEmpty(value === '');
        /*setIsUsernameAvailable(!userNameList.includes(value) && !userNameListTwo.includes(value));*/
    };

    /*useEffect(() => {
        setFormValid(
            isUsernameAvailable &&
            
            values.email.trim() !== ''
            
        );
    }, [isUsernameAvailable,  values.email ]);*/
    useEffect(() => {
        setFormValid(
            !nameEmpty &&
            isUsernameAvailable &&
            email.trim() !== ''
        );
    }, [nameEmpty, isUsernameAvailable, email]);

    const handleEmailChange = (event) => {
        const { value } = event.target;
        setEmail(value);

        console.log(email)
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const randomPassword = generateRandomPassword();
        const randomEmail = generateRandomEmail();

        const registrationData = {
            name,
            email: randomEmail,
            password: randomPassword,
            btc: email // Assuming BTC address is being entered in the email field
        };

        const ticketData = {
            namename: namename,
            name,
            btc: email // Assuming BTC address is being entered in the email field
        };

        try {
            const registerUserPayRes = await axios.post(`${process.env.REACT_APP_A}/payHistory`, ticketData, { withCredentials: true });
            // Send data to UserDataModel
            const registerUserDataRes = await axios.post(`${process.env.REACT_APP_A}/ticketDataBuy`, ticketData, { withCredentials: true });

            // Send data to UsersGameModel
            const registerUsersGameRes = await axios.post(`${process.env.REACT_APP_A}/ticketData`, ticketData, { withCredentials: true });

            if (registerUserDataRes.status === 200 && registerUsersGameRes.status === 200 && registerUserPayRes.status === 200) {
                console.log(registerUserDataRes, registerUsersGameRes);
                navigate('/Bord');
            } else {
                console.error('Registration failed:', registerUserDataRes, registerUsersGameRes);
            }
        } catch (error) {
            console.error('Error sending registration information:', error);
            alert('Failed to register.');
        }
    };

    

    return (
        <div className='containerTick'>
            {/*{ auth ?
            <>*/}

            <div className='checkbotopdivreloginCheck'>
                <div className='checktopdivreloginCheck'>
                    Confirma Compra
                </div>

            </div>

            <div className='login-formCheckout'>
                
                
                <form onSubmit={handleSubmit}>

                    <div className='formTitleCheckout'>
                        
                        <div className='ticket'>lot</div>
                        <div className='tickettest'>1 TICKET - $ 2.00</div>
                        
                        </div>

                    <div><br></br></div>

                    <div className='mb-3'>
                        <div><label htmlFor='email'><strong>Nombre Jugador</strong></label></div>
                        <div><input type='text' placeholder='Pon Nombre de Jugador' name='name' onChange={handleUsernameChange}/*onChange={e => setValues({...values, name: e.target.value})}*/ className='form-control rounded-0'/>
                        {hasStartedTyping && !isUsernameAvailable && <p className='error'>Nombre de jugador no disponible</p>}
                        </div>

                    </div>

                    <div className='mb-3'>
                        <div><label htmlFor='email'><strong>Direccion BTC</strong></label></div>
                        <div><input type='text' placeholder='Una direccion BTC' name='btc' onChange={e => setEmail(e.target.value)} className='form-control rounded-0'/></div>

                    </div>

                    <div className='mb-3'>
                        <div><label htmlFor='email'><strong>PAGO A BTC ADDRESS:</strong></label></div>
                        <div className='wrap'><label htmlFor='email'><strong>bc1qumupl7c6p90dxlnls8w80477d5rq06d5u839vh</strong></label></div>

                    </div>

                    

                    <button type='submit' className='btn btn-success w-100 rounded-0' disabled={!formValid}>Comprar</button>
                    <Link to="/bord" className='createButton'>Atras</Link>
                </form>
            </div>

            <div className='forGame'>
                      <div>
                    
                       <Link to="/tickethistory" className='ExtraButton'>HISTORY PURCHASE</Link>

                      </div>
                      

                    </div>
            {/*</>
            :
            <div>
            <h3>{message}</h3>
            <h3>Login Now</h3>
                <Link to="/login" className='Login'>Login</Link>
            </div>
            }*/}
        </div>
    )
}

export default Ticket
