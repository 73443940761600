import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import './about.css';
import logime from './pngs/lott.png'


function Login() {
    const [values, setValues] = useState({
        email: '',
        password: ''
    })

    const navigate = useNavigate()
    axios.defaults.withCredentials = true;
    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post('http://localhost:3001/login', values, { withCredentials: true })
        .then(res => {
            console.log('Server Response:', res.data);
            if(res.data.Status === "Success") {
                navigate('/Bord')
                document.cookie = `token=${res.data.Token}; path=/; Secure; SameSite=None;`;
                console.log('Logged');
            } else {
                alert(res.data.Error);
            }
        })
        .catch(err => console.log(err));
    }

    return (
        <div className='Aboutcontainer'>

            <div className='webtitle'>
                <div className='logodiv'><img src={logime} className='logolott' /></div>

                <div className='title'>LOTESIMPLE</div>
            </div>

            <div className='legendMain'>
                <br></br>
                <div className='headMain'>Como Jugar?</div>
                    
                    {/*<div>Selecciona los numeros que piense que caeran, pon una cantidad de $btc, y tira el dado! </div>*/}
                    {/*<div className='test'>Selecciona cualquiera de los numeros del 1 al 6. Coloca una cantidad de $Lottocoins, y tira el dado! </div>*/}
                    
                    <div className='testMainOne'>
                    <div className='testMain'>La cantidad de premio BOTE aumenta con cada ticket comprado.</div>
                    </div>

                   
                    
                    <br></br>
                    <div className='testMainOne'>
                    <div className='testMain'><strong>1.</strong> Compra 1 ticket token.</div>
                    <div className='testMain'><strong>2.</strong> Espera que el tiempo llegue a 0:00.</div>
                    <div className='testMain'><strong>3.</strong> El jugador seleccionado GANA todo el premio BOTE.</div>
                    </div>
                    <br></br>
                    <div className='testMainOne'>
                    <div className='testMain'>Cuando el tiempo llega a 0:00
                        </div>
                    <div className='testMain'> Se escoge automaticamente 1 jugador al azar</div>
                    </div>
                    <br></br>
                    <div className='testMainOne'>
                    <div className='testMain'>Puedes jugar anonimo o crear una cuenta.
                        </div>
                        </div>



                    
                    <br></br>
                    <div className='testMainOne'>
                    <div className='testMain'> LOTERY SIMPLE DIARIO</div>
                    
                    <div className='ruleMain'>El registro comienza a las 7:10 P.M. (Hora PST) </div>
                    <div className='ruleMain'>El GANADOR es seleccionado a las 7:00 P.M. (Hora PST)</div>
                    <br></br>
                    <div className='ruleMain'>La plataforma carga 12.5 % del bote.</div>
                    <br></br>
                    <div className='ruleMain'>PRONTO: Canal de Youtube con</div>
                    <div className='ruleMain'>seleccion en VIVO y grabaciones displonibles.</div>
                    </div>
                    <br></br>
                    <br></br>
                    
                    
                </div>

                <div className='welcome'>
                <Link className='welcomeInk' to="/login">Jugar Ahora</Link>
            </div>
            
            
        </div>
    )
}

export default Login
