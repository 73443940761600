import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import './Login.css';

function Login() {
    const [values, setValues] = useState({
        email: '',
        password: ''
    })

    const navigate = useNavigate()
    axios.defaults.withCredentials = true;
    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post(`${process.env.REACT_APP_A}/login`, values)
            .then(res => {
                console.log('Server Response:', res.data);
                if (res.data.success) {
                    // Store the token in localStorage
                    /*localStorage.setItem('token', res.data.token);*/
                    document.cookie = `token=${res.data.token}; path=/; Secure; SameSite=None;`;

                    console.log('Logged in');
                    navigate('/Bord');
                } else {
                    alert(res.data.message);
                }
            })
            .catch(err => console.log(err));
    };
    return (
        <div className='containerLog'>
            
            
            <div className='welcome'>
                <Link className='welcomeInk' to="/about">Como Jugar?</Link>
            </div>

            <div className='bomenubarLogin'>

                <div className='menubarLogin'>
                    Entra Cuenta
                </div>
            </div>
            <div className='login-formLogin'>
                
                <h2>Entrar</h2>
                <form onSubmit={handleSubmit}>
                    <div className='mb-3'>
                        <div><label htmlFor='email'><strong>Correo</strong></label></div>
                        <div><input type='text' placeholder='Enter Email' name='email' onChange={e => setValues({...values, email: e.target.value})} className='form-control rounded-0'/></div>

                    </div>

                    <div className='mb-3'>
                        <div><label htmlFor='password'><strong>Contrasena</strong></label></div>
                        <div><input type='text' placeholder='Enter Password' name='password' onChange={e => setValues({...values, password: e.target.value})} className='form-control rounded-0'/></div>
                        
                    </div>

                    <button type='submit' className='btn btn-success w-100 rounded-0'>Entrar</button>
                    <Link to="/register" className='createButton'>O Crea Cuenta</Link>
                </form>
            </div>

            {/*<div className='Admin'>
                Admin
             </div>*/}
        </div>
    )
}

export default Login
