import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './Landpage.css';
import logime from './pngs/lot.png';

import { io } from 'socket.io-client';

function Landpage() {
    const [userNames, setUserNames] = useState([]);
    const [userNamesGame, setUserNamesGame] = useState([]);

    const [chosenUser, setChosenUser] = useState(null);
    const [countdown, setCountdown] = useState(null);

    const [winners, setWinners] = useState([]);

    const [tempShowHighlight, setTempShowHighlight] = useState(false);
    const [effectTookPlace, setEffectTookPlace] = useState(true);

    const [isHighlighting, setIsHighlighting] = useState(false); 
    const [repDiv, setRepDiv] = useState(false);

    // Fetch all data in a single API call
    useEffect(() => {
        const fetchAllData = async () => {
            try {
                
                const { data } = await axios.get(`${process.env.REACT_APP_A}/getAllData`, { withCredentials: true });
                
                setUserNames(data.users);
                setUserNamesGame(data.gameUsers);
                setWinners(data.winners);
            } catch (err) {
                console.log(err);
            }
        };
        fetchAllData();

        const socket = io('https://garrulous-ebony-server.glitch.me');

        // Optionally, listen to any other events if needed
        // socket.on('someEvent', (data) => {
        //    console.log(data);
        // });

        // Cleanup on component unmount
        return () => {
            socket.disconnect();
        };
    }, []);

    const highlightUsers = () => {
        if (isHighlighting) return;
        setIsHighlighting(true);

        console.log('Highlighting Users');
        console.log('Winners:', winners);
        console.log('Chosen User:', chosenUser);
        const userBoxes = document.querySelectorAll('.UserNameBox');
        let currentIndex = 0;

        setTempShowHighlight(true);
        setRepDiv(true);
        
        const highlightInterval = setInterval(() => {
            // Apply yellow border to the current user
            userBoxes[currentIndex].style.border = '6px solid yellow';
    
            // Remove yellow border from the previous user
            if (currentIndex > 0) {
                userBoxes[currentIndex - 1].style.border = '';
            } else if (currentIndex === 0) {
                userBoxes[userBoxes.length - 1].style.border = '';
            }
    
            currentIndex++;
    
            // Reset index if it goes beyond the number of users
            if (currentIndex >= userBoxes.length) {
                currentIndex = 0;
            }
        }, 150);

        // Additional effect
        const intervalId = setInterval(() => {
            setRepDiv(prevRepDiv => !prevRepDiv);
        }, 1500);

        // Stop highlighting after 10 seconds (same duration as the lottery countdown)
        setTimeout(() => {
            clearInterval(highlightInterval);
            clearInterval(intervalId);

            // Clear yellow borders from all users except the chosen user
            userBoxes.forEach((userBox, index) => {
                if (userBox.textContent.trim() !== chosenUser) {
                    userBox.style.border = '';
                } else {
                    if (!tempShowHighlight) {
                        userBox.style.border = '6px solid yellow';
                    } else {
                        userBox.style.border = '';
                    }
                }
            });

            setTempShowHighlight(false);
            console.log('Took', effectTookPlace);
            setEffectTookPlace(false);
            setRepDiv(false);
            setIsHighlighting(false);
        }, 10000);
    };

    useEffect(() => {
        console.log("useEffect triggered with winners:", winners);
        if (winners.length > 0) {
            // Call highlightUsers after a short delay to allow DOM rendering
            const delay = setTimeout(() => {
                highlightUsers();
            }, 100);

            // Cleanup function to clear the timeout in case the component unmounts
            return () => {
                clearTimeout(delay);
            };
        }
    }, [winners]);

    return (
        <div className='Landpage'>
            <div className='iframediv'>
                <iframe
                    title='Countdown'
                    width='100%'        
                    height='300'
                    frameborder='0'
                    scrolling='no'
                    src='https://www.tickcounter.com/countdown/5648842/lote-cuentabaja'
                ></iframe>
            </div>

            <div className='pool'>
                <h3>B&nbsp;O&nbsp;T&nbsp;E:</h3> <div className='prize'><h1> &nbsp;$ 1 . 7 5 </h1></div>
            </div>

            <div className='menu'>
                <div className='menudiv'>BIENVENIDO</div>
                <div className='menudivAbout'>
                    <Link to='/about' className='menudivAboutInk'>COMO JUGAR?</Link>
                </div>
                <div className='menudivGR'>
                    <Link to='/register' className='menudivBuyInk'>CREA UNA CUENTA</Link>
                </div>
                <div className='menudivBuy'>
                    <Link to='/checkout' className='menudivBuyInk'>COMPRAR TICKET</Link>
                </div>
            </div>

            <div className='users'>
                <div className='UserList'>
                    <div className='winnertab'>
                        <div className='otherdiv'>
                            <h3>JUGADORES&#9660;</h3>
                        </div>

                        <div className={`repDiv ${repDiv ? 'intermittent' : ''}`}>
                            {repDiv && (
                                <div className='h3'><strong>REPETICION SELECCION</strong></div>
                            )}
                            {!repDiv && (
                                <div className='webtitle'>
                                    <div className='logodiv'><img src={logime} className='logolott' /></div>
                                    <div className='title'>LOTESIMPLE</div>
                                </div>
                            )} 
                        </div>

                        <div className='winnerdiv'>
                            <div>
                                {tempShowHighlight && !effectTookPlace && (
                                    <h3>GANADOR: </h3>
                                )} 
                                {tempShowHighlight && effectTookPlace && (
                                    <h3>GANADOR: </h3>
                                )} 
                                {!tempShowHighlight && !effectTookPlace && (
                                    <h3>GANADOR: <div className='winame'> {chosenUser ? chosenUser : winners.map(winner => winner.name).join(', ')}</div></h3>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='UserNameContainer'>
                        {userNames.map((user, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`UserNameBox ${!tempShowHighlight && winners.some(winner => winner.name === user.name) ? 'Winner' : ''}`}
                                >
                                    {user.name}
                                </div>
                            );
                        })}

                        {userNamesGame.map((user, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`UserNameBox ${!tempShowHighlight && winners.some(winner => winner.name === user.name) ? 'Winner' : ''}`}
                                    style={{ backgroundColor: '#d88d47', color: 'white' }}
                                >
                                    {user.name}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            <div className='forGame'>
                <div className='historialbutton'>
                    <Link to="/historygame" className='ExtraButton'>HISTORIAL GANADORES</Link>
                </div>

                

                
            
            </div>

            <div className='forGame'>
               
            <div className='aButton'>JUEGO ID: H3F7J8K2P1</div>

                <div className='aButton'>BETA V. 1. 2.</div>

                <div className='aButton'>WEBSITE STATUS: ACTIVO</div>
            
            </div>
        </div>
    );
};

export default Landpage;
