import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import './addbought.css';

function AddBuy() {
    const [auth, setAuth] = useState(false);
    const [message, setMessage] = useState('')
    const [name, setName] = useState('')
    const [id, setId] = useState('')
    axios.defaults.withCredentials = true;

    const [email, setEmail] = useState('');

    const [success, setSuccess] = useState(false);

    /*const [selectedLottocoins, setSelectedLottocoins] = useState('');*/

    const location = useLocation();
    /*const selectedLottocoins = location.state.selectedLottocoins || '';*/

    /*const [values, setValues] = useState({
        email: '',
        password: ''
    })*/

    const [values, setValues] = useState({
        email: '',
        password: ''
    })

    const navigate = useNavigate()
    axios.defaults.withCredentials = true;
    
    /*const handleSubmit = (event) => {
        event.preventDefault();
        
        .then(res => {
            if(res.data.Status === "Success") {
                navigate('/Bord')
            } else {
                alert(res.data.Error);
            }
        })
        .catch(err => console.log(err));
    } */

    /*useEffect(()=> {
        axios.get('http://localhost:3001')
          .then(res => {
              if(res.data.Status === "Success") {
                  console.log(res);
                  setAuth(true)
                  setName(res.data.name)
                  
                  
                  setId(res.data.id?.$oid || res.data.id);
                  
              } else {
                  setAuth(false)
                  setMessage(res.data.Error)
              }
          })
          .catch(err => console.log(err));
      }, [])*/

      /*useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const lottocoins = searchParams.get('lottocoins');
        setSelectedLottocoins(lottocoins);
    }, [location.search]);*/

    const generateRandomPassword = () => {
        const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()';
        let password = '';
        for (let i = 0; i < 8; i++) {
            password += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return password;
    };

    const generateRandomEmail = () => {
        const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let email = '';
        for (let i = 0; i < 5; i++) {
            email += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return email + '@short.com';
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const randomPassword = generateRandomPassword();
        const randomEmail = generateRandomEmail();

        const registrationData = {
            name,
            email: randomEmail,
            password: randomPassword,
             // Assuming name is already being set correctly
            btc: email // Assuming BTC address is being entered in the email field
        };

        const buyData = {
            name,
            btc: email // Assuming BTC address is being entered in the email field
        };
        
        try {
            /*const res = await axios.post('http://localhost:3001/sendPurchaseInfo', {
                name,
                email,
                
            });

            if (res.data.success) {
                
                setSuccess(true)
                navigate('/successbuy', { state: { success: res.data.success } });
            } else {
                setSuccess(false)
                navigate('/successbuy', { state: { success: res.data.success } });
            }*/

            const registerRes = await axios.post('http://localhost:3001/userbuy', buyData, { withCredentials: true });

            if (registerRes.status === 200) {
                console.log(registerRes);
                navigate('/Bord');
            } else {
                console.error('Registration failed:', registerRes);
            }

        } catch (error) {
            console.error('Error sending purchase information:', error);
            alert('Failed to send purchase information.');
            setSuccess(false);
            navigate('/successbuy', { state: { success: false } });
        }
    }

    /*const handleSubmit = async (event) => {
        event.preventDefault();

        const randomPassword = generateRandomPassword();
        const randomEmail = generateRandomEmail();

        const registrationData = {
            name,
            email: randomEmail,
            password: randomPassword,
            btc: email // Assuming BTC address is being entered in the email field
        };

        const gameData = {
            name,
            btc: email // Assuming BTC address is being entered in the email field
        };

        try {
            // Send data to UserDataModel
            const registerUserDataRes = await axios.post('http://localhost:3001/registerUserData', registrationData, { withCredentials: true });

            // Send data to UsersGameModel
            const registerUsersGameRes = await axios.post('http://localhost:3001/registerUsersGame', gameData, { withCredentials: true });

            if (registerUserDataRes.status === 200 && registerUsersGameRes.status === 200) {
                console.log(registerUserDataRes, registerUsersGameRes);
                navigate('/login');
            } else {
                console.error('Registration failed:', registerUserDataRes, registerUsersGameRes);
            }
        } catch (error) {
            console.error('Error sending registration information:', error);
            alert('Failed to register.');
        }
    };*/

    

    return (
        <div className='container'>
            {/*{ auth ?
            <>*/}

            <div className='botopdivreloginCheckout'>
                <div className='topdivreloginCheckout'>
                    Add Jugagor
                </div>

            </div>

            <div className='login-formCheckout'>
                
                
                <form onSubmit={handleSubmit}>

                    

                    <div><br></br></div>

                    <div className='mb-3'>
                        <div><label htmlFor='email'><strong>Nombre Jugador</strong></label></div>
                        <div><input type='text' placeholder='Nombre de Jugador' name='name' onChange={e => setName(e.target.value)} className='form-control rounded-0'/></div>

                    </div>

                    <div className='mb-3'>
                        <div><label htmlFor='email'><strong>DIRECCION</strong></label></div>
                        <div><input type='text' placeholder='Nombre de Jugador' name='btc' onChange={e => setEmail(e.target.value)} className='form-control rounded-0'/></div>

                    </div>

                    

                    <button type='submit' className='btn btn-success w-100 rounded-0'>Add</button>
                    <Link to="/Bord" className='createButton'>Atras</Link>
                </form>
            </div>
            {/*</>
            :
            <div>
            <h3>{message}</h3>
            <h3>Login Now</h3>
                <Link to="/login" className='Login'>Login</Link>
            </div>
            }*/}
        </div>
    )
}

export default AddBuy