import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Bord from './Bord'
import Register from './Register'
import Login from './Login'
import Landpage from './Landpage'
import About from './about'
import Checkout from './checkout'
import AddBuy from './addbought'
import Ticket from './ticket'
import HistoryGame from './historygame'
/*import Admin from './admin'*/
import Tickethistory from './tickethistory'

function App() {
  return (
    
    <BrowserRouter>
    <Routes>
      {/*<Route path='/' element={<homeCatalog />}></Route>*/}
      <Route path='/register' element={<Register />}></Route>
      <Route path='/login' element={<Login />}></Route>
      <Route path='/Bord' element={<Bord />}></Route>
      <Route path='/' element={<Landpage />}></Route>
      <Route path='/about' element={<About />}></Route>
      <Route path='/checkout' element={<Checkout />}></Route>
      <Route path='/addbuy' element={<AddBuy />}></Route>
      <Route path='/ticket' element={<Ticket />}></Route>
      <Route path='/historygame' element={<HistoryGame />}></Route>
      {/*<Route path='/admin' element={<Admin />}></Route>*/}
      <Route path='/tickethistory' element={<Tickethistory />}></Route>
    </Routes>
    </BrowserRouter>

  );
}

export default App;
