import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import './tickethistory.css';

function Tickethistory() {
    const [auth, setAuth] = useState(false);
    const [message, setMessage] = useState('')
    const [name, setName] = useState('')
    const [id, setId] = useState('')
    axios.defaults.withCredentials = true;


    const [history, setHistory] = useState([]);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                /*const token = localStorage.getItem('token');*/
                const token = document.cookie.split('token=')[1];
                const response = await axios.get(`${process.env.REACT_APP_A}/user`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
    
                if (response.data.success) {
                    setAuth(true);
                    setName(response.data.user.name);
                    /*setBalance(response.data.user.balance);
                    setId(response.data.user.id?.$oid || response.data.user.id);
                    setNameID(response.data.user.id);*/
                } else {
                    setAuth(false);
                    setMessage(response.data.message);
                }
            } catch (err) {
                console.log(err);
            }
        };
    
        fetchUserData();
    }, []);

      useEffect(() => {
       /*if (auth) {*/
       if (name) {
       console.log(name);
       console.log('Fetching purchase history for:', name);
       getPurchaseHistory(name);
       
            /*axios.get(`http://localhost:3001/purchaseHistory/${name}`)
                .then(res => {
                    if (res.data.success) {
                        
                        console.log('Retrieved history:', res.data.history);
                        const sortedHistory = res.data.history.sort((a, b) => new Date(b.date) - new Date(a.date));
                        const recentHistory = sortedHistory.slice(0, 10);
                        setHistory(recentHistory);
                        console.log(name);
                    } else {
                        setMessage('Failed to load purchase history');
                    }
                })
                .catch(err => console.log(err));*/
        
            }
    }, /*[auth]*/[name]);

    const getPurchaseHistory = (name) => {
        axios.get(`${process.env.REACT_APP_A}/purchaseHistory/${name}`)
        .then(res => {
            console.log('Response from /purchaseHistory:', res);
            if (res.data.status = 200) {
                /*setHistory(res.data.history);*/
                console.log('Retrieved history:', res.data.history);
                const sortedHistory = res.data.history.sort((a, b) => new Date(b.date) - new Date(a.date));
                const recentHistory = sortedHistory.slice(0, 10);
                setHistory(recentHistory);
                console.log(name);
                console.log('Updated history state:', recentHistory);
            } else {
                setMessage('Failed to load purchase history');
            }
        })
        .catch(err => console.log(err));
    }

    return (
        <div className='containerTicket'>
            {/*{ auth ?

            <>*/}

            <div className='botopdivreloginGame'>
                <div className='topdivreloginCheckoutSuccess'>
                    Historial Compra
                </div>

            </div>
            

            <div className='betdivSuccess'>
    <div className='legendHistoryGame'>
        <table className='historyTableGame'>
            <thead>
                <tr className='glegendHistoryGame'>
                    <th className='divlegendHistoryGame'>Nombre</th>
                    <th className='divlegendHistoryGame'>Fecha</th>
                    <th className='divlegendHistoryGame'>Status</th>
                </tr>
            </thead>
            <tbody className='historyListGame'>
                {history.map((item, index) => (
                    <tr key={index} className='historyItemGame'>
                        <td className='historyAmountGame'>{item.name}</td>
                        <td className='historyDateGame'>{new Date(item.date).toLocaleDateString()}</td>
                        <td className='historyStatusBuy'>{item.status}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        <div className='buyButtonGame'>
            <Link to="/Bord" className='buySuccessGame'>Jugar</Link>
        </div>
    </div>
</div>


            

            <div className='botdiv'>
                
                
                </div>
                
                {/*</>

                :
   

            <div className='loggedGame'>
            <h3 className='loginButtontestGame'>{message}</h3>
            <h3 className='loginButtontestGame'>Login Now</h3>
                <Link to="/login" className='loginButtonGame'>Login</Link>
                            </div>
            }*/}
                    
        
        </div>
    )
}

export default Tickethistory;
