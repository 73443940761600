import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './historygame.css';

function Historygame() {
    const [history, setHistory] = useState([]);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchHistory = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_A}/gameHistory`, { withCredentials: true });
                if (res.data.success) {
                    console.log('Retrieved history:', res.data.history);
                    
                    // Sort history by date, most recent first
                    const sortedHistory = res.data.history.sort((a, b) => new Date(b.date) - new Date(a.date));
                    
                    // Limit to the 10 most recent entries
                    const recentHistory = sortedHistory.slice(0, 10);
                    
                    setHistory(recentHistory);
                } else {
                    setMessage('Failed to load game history');
                }
            } catch (err) {
                console.log(err);
                setMessage('An error occurred while fetching history');
            }
        };

        fetchHistory();
    }, []);

    return (
        <div className='containerGame'>
            <div className='botopdivreloginGame'>
                <div className='topdivreloginCheckoutSuccess'>
                    Historial Juegos
                </div>
            </div>

            <div className='betdivSuccess'>
                <div className='legendHistoryGame'>
                    <table className='historyTableGame'>
                        <thead>
                            <tr className='glegendHistoryGame'>
                                <th className='divlegendHistoryGame'>Ganador</th>
                                <th className='divlegendHistoryGame'>IDJuego</th>
                                <th className='divlegendHistoryGame'>Fecha</th>
                            </tr>
                        </thead>
                        <tbody className='historyListGame'>
                            {history.length > 0 ? (
                                history.map((item, index) => (
                                    <tr key={index} className='historyItemGame'>
                                        <td className='historyAmountGame'>{item.name}</td>
                                        <td className='historyResultGame'>{item.game}</td>
                                        <td className='historyDateGame'>{new Date(item.date).toLocaleDateString()}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3" className='historyNoDataGame'>No history available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className='buyButtonGame'>
                        <Link to="/" className='buySuccessLinkGame'>Jugar</Link>
                    </div>
                </div>
            </div>

            {message && (
                <div className='messageDivGame'>
                    <p className='messageTextGame'>{message}</p>
                </div>
            )}

            <div className='botdiv'></div>
        </div>
    );
}

export default Historygame;
