import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import './Register.css';

function Register() {
    /*const [values, setValues] = useState({
        
        email: '',
        password: ''
    })*/

    
    /*let see if this is okay, if not use*/
    const [values, setValues] = useState({
        name: '',
        email: '',
        password: '',
        btc:''
    })


    const [userName, setUserName] = useState([]);
    const [isUsernameAvailable, setIsUsernameAvailable] = useState(false);
    const [passwordError, setPasswordError] = useState(true);

    const [hasStartedTyping, setHasStartedTyping] = useState(false);
    const [hasStartedTypingPassword, setHasStartedTypingPassword] = useState(false);

    const [isFormValid, setIsFormValid] = useState(false);

    const [nameEmpty, setNameEmpty] = useState(true);

    const navigate = useNavigate()
    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post(`${process.env.REACT_APP_A}/register`, values, { withCredentials: true })
        .then(res => {
            console.log(res);
            navigate('/login')
            
        })
        
        .catch(err => console.log(err));
        
    }

    useEffect(() => {
        // Fetch the list of usernames when the component mounts
        axios.get(`${process.env.REACT_APP_A}/getUsersName`)
            .then(res => {
                setUserName(res.data.usernames); // Assume the response contains an array of usernames
                console.log(res.data);
            })
            .catch(err => console.log(err));
    }, []);

    const handleUsernameChange = (event) => {
        const { value } = event.target;
        setValues({ ...values, name: value });


        if (!hasStartedTyping && value) {
            setHasStartedTyping(true);
        }

        // Check if the username is available
        if (userName.includes(value)) {
            setIsUsernameAvailable(false);
        } else {
            setIsUsernameAvailable(true);
        }

        setNameEmpty(value === '');
    };

    const handlePasswordChange = (event) => {
        const { value } = event.target;
        setValues({ ...values, password: value });

        if (!hasStartedTypingPassword && value) {
            setHasStartedTypingPassword(true);
        }
        // Validate the password
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{7,}$/;
        if (!passwordRegex.test(value)) {
            setPasswordError('Password must be at least 7 characters long and include at least 1 lowercase letter, 1 uppercase letter, 1 number, and 1 symbol.');
        } else {
            /*setPasswordError('');*/
            setPasswordError(false);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setValues({ ...values, [name]: value });
    };

    /*const isFormValid = isUsernameAvailable && !passwordError;*/

    useEffect(() => {
        setIsFormValid(
            !nameEmpty &&
            isUsernameAvailable &&
            !passwordError &&
            values.email.trim() !== '' &&
            values.btc.trim() !== ''
        );
    }, [nameEmpty, isUsernameAvailable, passwordError, values.email, values.btc]);
    

    return (
        <div className='containerRG'>
            
            <div className='bomenubarRG'>
                <div className='menubarRG'>Crea una cuenta</div>
            </div>
            <div className='login-formRG'>
                
                <h2>Crea Cuenta</h2>
                <form onSubmit={handleSubmit}>
                <div className='mb-3'>
                        <div><label htmlFor='name'><strong>Nombre Cuenta</strong></label></div>
                        <div><input type='text' placeholder='Pon Nombre de Jugador' name='name' onChange={handleUsernameChange}/*onChange={e => setValues({...values, name: e.target.value})}*/ className='form-control rounded-0'/>
                        {hasStartedTyping && !isUsernameAvailable && <p className='error'>Nombre de jugador no disponible</p>}
                        </div>

                    </div>
                    
                    <div className='mb-3'>

                        <div><label htmlFor='email'><strong>Email Cuenta</strong></label></div>
                        <div><input type='text' placeholder='Email' name='email' onChange={e => setValues({...values, email: e.target.value})} className='form-control rounded-0'/></div>

                    </div>

                    <div className='mb-3'>
                        <div><label htmlFor='password'><strong>Contrasena Cuenta</strong></label></div>
                        <div><input type='text' placeholder='Una contrasena' name='password' onChange={handlePasswordChange}/*onChange={e => setValues({...values, password: e.target.value})}*/ className='form-control rounded-0'/>
                        {hasStartedTypingPassword && passwordError && <p className='error'>{passwordError}</p>}
                        </div>
                        
                    </div>

                    <div className='mb-3'>
                        <div><label htmlFor='address'><strong>Direccion BTC si Gana</strong></label></div>
                        <div><input type='text' placeholder='Una direccion BTC' name='btc' onChange={e => setValues({...values, btc: e.target.value})} className='form-control rounded-0'/></div>
                        
                    </div>

                    <button type='submit' className='btn btn-success w-100 rounded-0' disabled={!isFormValid}>Crear Cuenta</button>
                    <Link to='/login' className='createButton'>O Entra</Link>
                    {/*btn btn-default border w-100 bg-light rounded-0 text-decoration-none*/}
                </form>
            </div>
        </div>
    )
}

export default Register
